import {TGIcon} from "./TGElements";
import TGImg from "./TGElements/TGImg";
import {t} from "i18next";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {Invest} from "../assets/images";

const InvestPopup = () => {
    const [isOpen, setIsOpen] = useState(false)
    const {user} = useSelector(state => state.user);

    useEffect(() => {
        if (user.token && !localStorage.getItem("isInvestPopupSeen")) {
            setIsOpen(true)
        }
    }, [])
    const close = () => {
        setIsOpen(false)
        localStorage.setItem("isInvestPopupSeen", true)
    }


    return (
        isOpen ?
            <div className={"WalletPopupContainer"}>
                <div className={"WalletPopup"}>
                    <div style={{position: "absolute", right: 30}} onClick={() => close()}>
                        <TGIcon
                            name={"gray-close"}
                            width={30}
                            height={30}
                            margin={"0 5px 0 20px"}
                        />
                    </div>
                    <div style={{marginTop: "60px"}}>
                        <TGImg src={Invest}/>
                    </div>
                    <div style={{fontSize: "14px", fontWeight: 450, marginTop: "20px"}}>
                    </div>

                    <button onClick={() => window.location = "https://toger.com.tr/fon"}
                            className={"GoToWallet"}>Talepte Bulun
                    </button>


                </div>
            </div>
            : null
    );
};

export default InvestPopup;
