import React from 'react';
import {setChangeBuyStationModal} from "../../redux/slices/modalSlice";
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import TGIcon from "../TGElements/TGIcon";
import {TGButton, TGImg, TGText} from "../TGElements";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import OrderDeviceImg from "../../assets/images/order-device.png";
import {Browser} from "@capacitor/browser";

const BuyStationModal = () => {
  const {buyStationModal} = useSelector(state => state.modal)
  const {t} = useTranslation()
  const dispatch = useDispatch()


  const gotoOrderDevice = async () => {
    dispatch(setChangeBuyStationModal(false))
    const res = await Browser.open({
      url:"https://toger.co/devices"
    })
  }


  return (
    <IonModal
      isOpen={buyStationModal}
      onDidDismiss={() => dispatch(setChangeBuyStationModal(false))}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top">
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={() => dispatch(setChangeBuyStationModal(false))}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end ion-text-center'}>

        <TGImg
          src={OrderDeviceImg}
          width={'80%'}
        />

        <TGText
          color="var(--tg-primary-text)"
          fontSize={20}
          fontWeight="semibold"
          textAlign="center"
          width="100%"
          margin={"15px 0 17px 0"}
        >
          {t("order-device-title")}
        </TGText>
        <TGText
          color={"var(--tg-secondary-text)"}
          fontSize={16}
          margin={"0 0 25px 0"}
          fontWeight="regular"
          textAlign={"center"}
          width="100%"
          textTransform={"none"}
        >
          {t('order-device-desc')}
        </TGText>

        <TGButton
          margin={"0 0 25px 0"}
          onButtonClick={gotoOrderDevice}
          backgroundColor={"var(--tg-dark)"}
          backgroundActiveColor={"var(--tg-black-active)"}
        >
          <TGText
            color={"var(--tg-white)"}
            fontSize={16}
            fontWeight="medium"
          >
            {t("order-now")}
          </TGText>
        </TGButton>
      </div>
    </IonModal>
  );
};

export default BuyStationModal;