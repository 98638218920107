//Helpers
import { useTranslation } from "react-i18next"
//Components
import { TGText } from "../../../TGElements"
import NearbyStationSlider from "./NearbyStationSlider"

const NearbyStations = () => {
    const {t} = useTranslation()
    return (
        <div className='NearbyStations'>
            <TGText
            fontWeight={"semibold"}
            fontSize={14}
            >
                {t("nearby")}
            </TGText>
            <NearbyStationSlider />
        </div>
    )
}

export default NearbyStations