//Ionic & React
import React, {useState} from "react";
//Components
import {
  MapButtons,
  TGMap,
  TGPage,
  Modals,
  ActiveSessionButton,
} from "../components";
//Helpers
import {getActiveSession, getNotifications, isUserLoggedIn} from "../helpers";
//Redux
import WalletPopup from "../components/Wallet/walletPopup";
import InvestPopup from "../components/investPopup";
import {useIonViewDidEnter} from "@ionic/react";
import MapBottomButtons from "../components/MapBottomButtons";

const Map = () => {
  const [map, setMap] = useState({});

  useIonViewDidEnter(() => {

    if (isUserLoggedIn()){
      getActiveSession(false)
      getNotifications()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TGPage
      scrollY={false}
      fullscreen={true}
      ionPadding={false}
      toolbar={false}
      cssClass={"TGMapPageContent"}
    >
      <WalletPopup/>
      <InvestPopup/>
      <TGMap map={map} setMap={setMap}/>
      <MapButtons map={map}/>
      <Modals map={map}/>
      <ActiveSessionButton />
      <MapBottomButtons />
    </TGPage>
  );
};

export default Map;
